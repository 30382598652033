import React, { useEffect } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/core'

// API
import { getVolunteerAccess } from '../../services/api/volunteerApi'

// Components
import { sproglandHeaderBackImage, sproglandHeaderStyle, sproglandHeaderTitle } from './SproglandOptions'
import ConversationCreate from '../../components/views/Conversations/ConversationCreate'
import MainContainer from '../../components/MainContainer/MainContainer'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'

// Chips
import { CustomChip } from '../../components/chips/Chip'
import MySessions from '../../components/views/LearnDanish/volunteer/MySessions'
import PreviousSessions from '../../components/views/LearnDanish/volunteer/PreviousSessions'
import Materials from '../../components/views/LearnDanish/volunteer/Materials'
import Messages from '../../components/views/LearnDanish/volunteer/Messages'
import VolunteerProfile from '../../components/views/LearnDanish/volunteer/VolunteerProfile'

const { width } = Dimensions.get('window')
const small = width < 800

export default function VolunteerScreen() {
	const route = useRoute()
	const navigation = useNavigation()
	const isSmallDevice = Dimensions.get('window').width < 420

	let chip = route.params?.chip

	const mine = txt('learnDanish.volunteer.chipMine')
	const previous = txt('learnDanish.volunteer.chipPrevious')
	const materials = txt('learnDanish.volunteer.chipMaterials')
	const messages = txt('learnDanish.volunteer.chipMessages')
	const profile = txt('learnDanish.volunteer.chipProfile')

	useEffect(() => {
		navigation.setOptions({
			headerBackTitle: small && ' ',
			headerBackTitleStyle: { color: Colors.text, fontFamily: 'raleway-regular' },
			headerBackImage: () => sproglandHeaderBackImage(navigation),
			headerTitle: () => sproglandHeaderTitle(true),
			headerStyle: sproglandHeaderStyle,
		})

		getVolunteerAccess('sprogland').then((volunteer) => {
			if (!volunteer.isVolunteer) navigation.navigate('LearnDanish')
		})
		
		let chip = route.params?.chip
		if (Number(chip)) {
			navigation.navigate('SproglandVolunteer', {
				id: chip,
			})
		} else if (!chip) {
			chip = mine
			setChipParam(chip)
		}
	}, [])

	useEffect(() => {
		navigation.setOptions({
			headerRight: () => {
				if (chip === messages) return <ConversationCreate />
				else return null
			},
		})
	}, [chip])

	const setChipParam = (chip) => navigation.setParams({ chip })

	function renderChips() {
		return (
			<View style={styles.chipContainer}>
				{/* Create + own bookings */}
				<View style={styles.chip}>
					<CustomChip
						icon="clock"
						iconStroke="fal"
						selected={route.params?.chip === mine}
						onPress={() => setChipParam(mine)}
						accessibilityLabel={txt('learnDanish.volunteer.mine')}
						text={mine.charAt(0).toUpperCase() + mine.slice(1)}
					/>
				</View>

				{/* Previous bookings */}
				<View style={styles.chip}>
					<CustomChip
						icon="history"
						iconStroke="fal"
						selected={route.params?.chip === previous}
						onPress={() => setChipParam(previous)}
						accessibilityLabel={txt('learnDanish.volunteer.previous')}
						text={previous.charAt(0).toUpperCase() + previous.slice(1)}
					/>
				</View>

				{/* Materials */}
				<View style={styles.chip}>
					<CustomChip
						icon="paperclip"
						iconStroke="fal"
						selected={route.params?.chip === materials}
						onPress={() => setChipParam(materials)}
						accessibilityLabel={txt('learnDanish.volunteer.materials.title')}
						text={!isSmallDevice && materials.charAt(0).toUpperCase() + materials.slice(1)}
					/>
				</View>

				{/* Inbox */}
				<View style={styles.chip}>
					<CustomChip
						icon="comment-lines"
						iconStroke="fal"
						selected={route.params?.chip === messages}
						onPress={() => setChipParam(messages)}
						accessibilityLabel={txt('learnDanish.volunteer.messages')}
						text={!isSmallDevice && messages.charAt(0).toUpperCase() + messages.slice(1)}
					/>
				</View>

				{/* Profile */}
				<View style={styles.chip}>
					<CustomChip
						icon="user"
						iconStroke="fal"
						selected={route.params?.chip === profile}
						onPress={() => setChipParam(profile)}
						accessibilityLabel={txt('learnDanish.volunteer.profile.title')}
						text={!isSmallDevice && txt('learnDanish.volunteer.profile.title')}
					/>
				</View>
			</View>
		)
	}

	switch (chip) {
		case previous:
			return (
				<MainContainer backgroundColor={Colors.redCrossBackground}>
					{renderChips()}
					<PreviousSessions />
				</MainContainer>
			)
		case materials:
			return (
				<MainContainer backgroundColor={Colors.redCrossBackground}>
					{renderChips()}
					<Materials />
				</MainContainer>
			)
		case messages:
			return (
				<MainContainer noScroll backgroundColor={Colors.redCrossBackground}>
					{renderChips()}
					<Messages />
				</MainContainer>
			)
		case profile:
			return (
				<MainContainer backgroundColor={Colors.redCrossBackground}>
					{renderChips()}
					<VolunteerProfile />
				</MainContainer>
			)
		case mine:
		default:
			return (
				<MainContainer backgroundColor={Colors.redCrossBackground}>
					{renderChips()}
					<MySessions />
				</MainContainer>
			)
	}
}

const styles = StyleSheet.create({
	chip: {
		marginBottom: 10,
		marginLeft: 5,
		marginRight: 5,
	},
	chipContainer: {
		alignSelf: 'center',
		flexDirection: 'row',
	},
})

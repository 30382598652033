import React, { useEffect, useState } from 'react'
import { View, StyleSheet, TextInput, Keyboard } from 'react-native'
import { useSelector } from 'react-redux'

import { SnakSammenSwitch } from '../../../switches/StyledSwitches'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import { ButtonPrimary } from '../../../buttons/StyledButtons'
import NormalIcon from '../../../icons/NormalIcon'
import Colors from '../../../../constants/Colors'
import RegexPatterns from '../../../../constants/RegexPatterns'

// API
import { updateUserPreferences } from '../../../../services/api/User'
import { getSproglandProfile, updateSproglandProfile } from '../../../../services/api/LearnDanish'

export const SproglandInput = ({ label, value, placeholder, onChange, multiline = false, style, error, errorMessage, success, maxLength, inputMode, autoCapitalize, editable = true }) => {
	return (
		<View>
			{label && <CustomText font="smallBold" style={styles.label}>{label}</CustomText>}
			<View style={{ marginBottom: 12 }}>
				<TextInput
					value={value}
					onChangeText={onChange}
					multiline={multiline ?? false}
					placeholder={placeholder}
					accessibilityLabel={placeholder}
					placeholderTextColor={Colors.textLight}
					maxLength={maxLength}
					inputMode={inputMode}
					autoCapitalize={autoCapitalize}
					style={[
						styles.input, { minHeight: multiline ? 150 : null },
						styles.inputText,
						error && { borderColor: Colors.red },
						style
					]}
					editable={editable}
				/>
				{maxLength && (
					<CustomText font="small" style={{ position: 'absolute', right: 6, bottom: -24 }}>
						{(value?.length ?? 0) + '/' + maxLength}
					</CustomText>
				)}
				{(error === true || success === true) && (
					<NormalIcon
						name={error ? "exclamation-circle" : "check-circle"}
						stroke="fas"
						size={18}
						color={error ? Colors.red : Colors.greenBtn}
						style={[styles.inputIcon, multiline && { top: 20 }]}
					/>
				)}
			</View>
			{error === true && (
				<CustomText font="xsmallBold" style={styles.errorMessage}>
					{errorMessage}
				</CustomText>
			)}
		</View>
	)
}

export default function VolunteerProfile() {
	const preferences = useSelector((state) => state.preferences)
	const userInfo = useSelector((state) => state.user)

	const [description, setDescription] = useState('')
	const [username, setUsername] = useState(userInfo.username ?? '')
	const [phone, setPhone] = useState(userInfo.phone ?? '')
	const [email, setEmail] = useState(userInfo.email ?? '')

	const [descriptionError, setDescriptionError] = useState(false)
	const [usernameError, setUsernameError] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [emailError, setEmailError] = useState(false)

	const [descriptionErrorMessage, setDescriptionErrorMessage] = useState(null)
	const [usernameErrorMessage, setUsernameErrorMessage] = useState(null)
	const [phoneErrorMessage, setPhoneErrorMessage] = useState(null)
	const [emailErrorMessage, setEmailErrorMessage] = useState(null)

	const [descriptionSuccess, setDescriptionSuccess] = useState(false)
	const [usernameSuccess, setUsernameSuccess] = useState(false)
	const [phoneSuccess, setPhoneSuccess] = useState(false)
	const [emailSuccess, setEmailSuccess] = useState(false)

	const [loading, setLoading] = useState(false)
	const [savedSuccessfully, setSavedSuccessfully] = useState(false)
	const [failedToSave, setFailedToSave] = useState(false)

	useEffect(() => {
		getSproglandProfile().then((profile) => {
			if (profile) setDescription(profile.description)
		})
	}, []);

	function handleSwitch(type, value) {
		updateUserPreferences({ [type]: value })
	}

	async function handleSave() {
		setLoading(true)
		Keyboard.dismiss()

		if (!validate()) {
			setFailedToSave(true)
			setLoading(false)
			return
		}

		const data = { username, email, phone }
		const updated = await updateSproglandProfile({ description, data })

		if (!updated) setFailedToSave(true)
		else setSavedSuccessfully(true)
		return setLoading(false)
	}

	function validEmail(email) {
		const pattern = RegexPatterns.email
		const conditions = pattern.map((rule) => new RegExp(rule, 'g'))
		const results = conditions.map((condition) => condition.test(email))
		return !results.includes(false)
	}

	function clearFeedback() {
		setDescriptionError(false)
		setUsernameError(false)
		setPhoneError(false)
		setEmailError(false)

		setDescriptionErrorMessage(null)
		setUsernameErrorMessage(null)
		setPhoneErrorMessage(null)
		setEmailErrorMessage(null)

		setDescriptionSuccess(false)
		setUsernameSuccess(false)
		setPhoneSuccess(false)
		setEmailSuccess(false)

		setSavedSuccessfully(false)
		setFailedToSave(false)
	}

	function validate() {
		clearFeedback()

		let valid = true

		if (description.length !== 0 && description.length < 10) {
			setDescriptionError(true)
			setDescriptionErrorMessage(txt('learnDanish.volunteer.profile.descriptionErrorMessage'))
			valid = false
		} else {
			setDescriptionSuccess(true)
		}

		if (username.length < 2) {
			setUsernameError(true)
			setUsernameErrorMessage(txt('learnDanish.volunteer.profile.usernameErrorMessage'))
			valid = false
		} else {
			setUsernameSuccess(true)
		}

		if (phone.length < 8 && phone.length !== 0) {
			setPhoneError(true)
			setPhoneErrorMessage(txt('learnDanish.volunteer.profile.phoneErrorMessage'))
			valid = false
		} else {
			setPhoneSuccess(true)
		}

		if (email.length < 5 || !email.includes('@') || !validEmail(email)) {
			setEmailError(true)
			setEmailErrorMessage(txt('learnDanish.volunteer.profile.emailErrorMessage'))
			valid = false
		} else {
			setEmailSuccess(true)
		}

		return valid
	}

	return (
		<View style={styles.container}>

			{/* PROFILE TEXT */}
			<View style={styles.section}>
				<CustomText font="xlarge">{txt('learnDanish.volunteer.profile.descriptionTitle')}</CustomText>
				<CustomText font="xsmall" style={{ marginVertical: 12, lineHeight: 16, color: Colors.inactive }}>
					{txt('learnDanish.volunteer.profile.descriptionBody')}
				</CustomText>

				<SproglandInput
					value={description}
					placeholder={txt('learnDanish.volunteer.profile.descriptionPlaceholder')}
					onChange={(text) => setDescription(text)}
					multiline
					error={descriptionError}
					errorMessage={descriptionErrorMessage}
					success={descriptionSuccess}
					maxLength={300}
					counter
				/>
			</View>

			{/* PROFILE SETTINGS */}
			<View style={styles.section}>
				<CustomText font="xlarge">{txt('learnDanish.volunteer.profile.settingsTitle')}</CustomText>

				<SproglandInput
					value={username}
					placeholder={txt('learnDanish.volunteer.profile.usernamePlaceholder')}
					onChange={(text) => setUsername(text)}
					label={txt('learnDanish.volunteer.profile.usernameTitle')}
					error={usernameError}
					errorMessage={usernameErrorMessage}
					success={usernameSuccess}
				/>

				<SproglandInput
					value={phone}
					placeholder={txt('learnDanish.volunteer.profile.phonePlaceholder')}
					onChange={(text) => setPhone(text)}
					label={txt('learnDanish.volunteer.profile.phoneTitle')}
					error={phoneError}
					errorMessage={phoneErrorMessage}
					success={phoneSuccess}
				/>

				<SproglandInput
					value={email}
					placeholder={txt('learnDanish.volunteer.profile.emailPlaceholder')}
					onChange={(text) => setEmail(text)}
					label={txt('learnDanish.volunteer.profile.emailTitle')}
					error={emailError}
					errorMessage={emailErrorMessage}
					success={emailSuccess}
				/>

				{savedSuccessfully && (
					<CustomText font="gotham-bold-small" style={styles.successText}>
						{txt('learnDanish.volunteer.profile.savedSuccessfully')}
					</CustomText>
				)}

				{failedToSave && (
					<CustomText font="gotham-bold-small" style={styles.errorText}>
						{txt('learnDanish.volunteer.profile.failedToSave')}
					</CustomText>
				)}

				<ButtonPrimary style={styles.button} titleStyle={styles.buttonTitle} onPress={handleSave} loading={loading}>
					{txt('learnDanish.volunteer.profile.saveButton')}
				</ButtonPrimary>
			</View>

			{/* NOTIFICATIONS */}
			<View style={styles.section}>
				<CustomText font="xlarge" style={{ marginBottom: 24 }}>
					{txt('settings.notifications')}
				</CustomText>

				<View style={styles.notificationGroup}>
					<SnakSammenSwitch
						title={txt('settings.notificationType.push')}
						value={preferences.sprogland_push_notification}
						handleChange={(value) => handleSwitch('sprogland_push_notification', value)}
					/>
					<View style={styles.separator} />
					<SnakSammenSwitch
						title={txt('settings.notificationType.email')}
						value={preferences.sprogland_email_notification}
						handleChange={(value) => handleSwitch('sprogland_email_notification', value)}
					/>
					<View style={styles.separator} />
					<SnakSammenSwitch
						title={txt('settings.notificationType.sms')}
						value={preferences.sprogland_sms_notification}
						handleChange={(value) => handleSwitch('sprogland_sms_notification', value)}
					/>
				</View>

			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		maxWidth: 800,
		width: '100%',
		flex: 1,
		alignSelf: 'center',
		padding: 12,
		marginTop: 12,
	},
	section: {
		marginBottom: 24,
	},
	notificationGroup: {
		marginBottom: 12,
		backgroundColor: Colors.white,
		paddingVertical: 6,
		paddingHorizontal: 12,
		borderWidth: 1,
		borderColor: Colors.redCrossGrey,
		borderRadius: 5,
	},
	separator: {
		borderBottomColor: Colors.greyLightLight,
		borderBottomWidth: 1,
	},
	label: {
		marginTop: 12,
		marginBottom: 9,
		paddingLeft: 3,
		color: Colors.redCross.text
	},
	input: {
		backgroundColor: Colors.white,
		borderRadius: 5,
		paddingTop: 12, // regular padding does not work on native
		paddingBottom: 12, // regular padding does not work on native
		paddingRight: 12, // regular padding does not work on native
		paddingLeft: 12, // regular padding does not work on native
		borderWidth: 1,
		borderColor: Colors.redCrossGrey,
		fontSize: 15,
	},
	inputText: {
		color: Colors.redCross.text,
		fontFamily: 'raleway-regular',
		fontSize: 15,
	},
	inputIcon: {
		position: 'absolute',
		right: 12,
		top: '50%',
		transform: [{ translateY: -9 }],
	},
	successText: {
		color: Colors.greenBtn,
		marginVertical: 12,
		alignSelf: 'center',
	},
	errorText: {
		color: Colors.red,
		marginVertical: 12,
		alignSelf: 'center',
	},
	errorMessage: {
		color: Colors.red,
		paddingLeft: 3,
		paddingTop: 6,
	},
})
import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { useNavigation, useRoute } from '@react-navigation/core'

import PostItem from './PostItem'
import { txt } from '../../../locales/i18n'
import { SpacingStyles } from '../../../styles'
import { CustomText } from '../../text/StyledText'
import { CreatePostCard } from './CreatePostCard'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import { Features } from '../../../constants/Features'
import { PromoteSubscriptionDialog } from './Dialogs/PromoteSubscriptionDialog'
import { SubscriptionPromoCard } from './SubscriptionPromoCard'

import { TooManyActivePostsDialog } from './Dialogs/TooManyActivePostsDialog'
import { useAtomValue } from 'jotai'
import { showSubscriptionDialogAtom, showTooManyActivePostsDialogAtom, forceReloadAtom } from '../../../atoms'

export default function MyPosts(props) {
	const [posts, setPosts] = useState([])
	const [showSubscriptionCard, setShowSubscriptionCard] = useState(false)

	const navigation = useNavigation()
	const userFeatures = useSelector((state) => state.user.features)
	const { params } = useRoute()
	const showSubscription = useAtomValue(showSubscriptionDialogAtom)
	const tooManyActivePosts = useAtomValue(showTooManyActivePostsDialogAtom)
	const forceReload = useAtomValue(forceReloadAtom)

	const newyearUniverses = ['nytaarsvenner', 'nyarsvanner']

	useEffect(() => {
		if (params?.chip !== txt('posts.chipParams.mine')) return

		getOwnPosts()
		navigation.addListener('focus', getOwnPosts)
		return () => navigation.removeListener('focus', getOwnPosts)
	}, [params])

	useEffect(() => {
		if (userFeatures.includes(Features.START_CONVERSATION)) return
		if (newyearUniverses.includes(props?.universe?.name)) return
		setShowSubscriptionCard(true)
	}, [params, forceReload])

	async function getOwnPosts() {
		const posts = await props.getOwnPosts()
		setPosts(posts)
	}

	function renderItem({ item }) {
		const isFaded = [-1, -2, -5].includes(item.status_id)
		return (
			<PostItem
				item={item}
				onPressAction={() => props.navigateToPost(item.id)}
				rightElement={
					<TouchableOpacity
						onPress={() => props.navigateToEditPost(item.id)}
						accessibilityRole="button"
						accessibilityLabel={txt('post.editTitle')}
						style={styles.center}
					>
						<NormalIcon name={item.status_id > 0 ? "eye" : "eye-slash"} color={Colors.textLight} />
						<CustomText font="small" style={{ color: Colors.textLight }}>
							{item.status_id > 0 ? txt('ownPosts.visible') : txt('ownPosts.hidden')}
						</CustomText>
					</TouchableOpacity>
				}
				isFaded={isFaded}
			/>
		)
	}

	function noPosts() {
		if (newyearUniverses.includes(props?.universe?.name)) {
			return (
				<CreatePostCard
					title={txt('home.createBoblTitleNewYear')}
					text={txt('home.createBoblTextNewYear')}
					navigateToCreatePost={props.navigateToCreatePost}
					universe={props.universe}
				/>
			)
		}

		return <CreatePostCard navigateToCreatePost={props.navigateToCreatePost} />
	}

	const renderListHeaderComponent = () => {
		if (showSubscriptionCard === false || posts.length === 0) return null
		return (
			<SubscriptionPromoCard
				title={txt('posts.promoteSubscriptionCard.myPosts.title')}
				description={txt('posts.promoteSubscriptionCard.myPosts.description')}
				origin={'my-posts-card'}
				universe={props.universe}
			/>
		)
	}

	return (
		<View style={[styles.container, { ...props.style }]}>
			{props.renderChips()}
			<FlatList
				ListHeaderComponent={renderListHeaderComponent}
				keyExtractor={(item) => item.id?.toString()}
				data={posts}
				renderItem={renderItem}
				keyboardDismissMode="on-drag"
				ListEmptyComponent={noPosts}
			/>

			<PromoteSubscriptionDialog visible={showSubscription} />
			<TooManyActivePostsDialog visible={tooManyActivePosts} />
		</View>
	)
}
const styles = StyleSheet.create({
	center: { alignSelf: 'center', paddingLeft: 12, alignItems: 'center' },
	container: { flex: 1, ...SpacingStyles.widthAndHeight },
})

import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { cancelBooking, getVolunteerProfile } from '../../../services/api/LearnDanish'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { ButtonPrimary, ButtonSecondary } from '../../buttons/StyledButtons'
import { ShadowStyles, TypographyStyles } from '../../../styles'
import moment from 'moment'
import { getConversationId } from '../../../services/api/Inbox'
import TextLabel from './TextLabel'
import startVideoSession from '../../../utils/startVideoSession'
import { getPreviousSLSurvey } from '../../../services/api/Surveys'
// import SproglandSurveyDialog from './SproglandSurveyDialog'
// import { WebBrowserResultType } from 'expo-web-browser'
import { getJuniorConsent } from '../../../services/api/LearnDanish'
import { SproglandJuniorConsentDialog } from '../../dialogs/SproglandJuniorConsentDialog'
import { CancelWithMessageDialog, CannotCancelDialog } from './dialogs/CancelDialogs'
import CustomPressable from '../../pressables/CustomPressable'
import SessionCategoryDialog from './dialogs/sessionCategoryDialog'
import { VolunteerProfileDialog } from './dialogs/VolunteerProfileDialog'

export default function AvailableSessions(props) {
	// const [dialogVisible, setDialogVisible] = useState(false)
	const [showConsentDialog, setShowConsentDialog] = useState(false)
	const [cannotCancelDialogVisible, setCannotCancelDialogVisible] = useState(false)
	const [cancelMessageDialogVisible, setCancelMessageDialogVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [sessionCategoryDialogVisible, setSessionCategoryDialogVisible] = useState(false)
	const [clickedCategoryId, setClickedCategoryId] = useState(null)
	const [volunteerProfile, setVolunteerProfile] = useState(null)
	const [volunteerProfileVisible, setVolunteerProfileVisible] = useState(false)

	//sets locale to danish time
	moment.locale([txt('videoChat.locale')])

	useEffect(() => {
		props.loadSessions()
	}, [])

	function formatUnixDate(date) {
		const d = moment(date).format('dddd [d.] DD/MM [kl.] HH:mm')
		return d.charAt(0).toUpperCase() + d.substr(1)
	}

	async function handleSendMessage(item) {
		const { volunteer_id, id } = item
		if (!volunteer_id || !id) return
		const conversationId = await getConversationId({
			slSessionId: id,
			receiverId: volunteer_id,
		})
		if (conversationId) props.navigateToConversation(conversationId)
		else props.navigateToNewConversation(volunteer_id, id)
	}

	async function handleStartPress(item) {
		let junior = !!props.juniorId
		if (junior) {
			const consentGiven = await getJuniorConsent()
			if (consentGiven === false) {
				return setShowConsentDialog(!consentGiven)
			}
		}

		const browser = await startVideoSession(item.room_key, item.vonage_session_id, junior ? 'sproglandJunior' : 'sprogland')
		const bookings = await getPreviousSLSurvey()
		// if (bookings.previouslyCompletedBookings === 0 && bookings.completedBookings > 0 || bookings.completedBookings - bookings.previouslyCompletedBookings >= 4) {
		// 	if (Platform.OS !== 'ios') setDialogVisible(true)
		// 	else if (browser.type === WebBrowserResultType.CANCEL) setDialogVisible(true)
		// }
	}

	async function handleCancelBooking(item) {
		const now = new Date()
		const startDate = new Date(item.start_date)
		const tenMinutesBeforeStartDate = new Date(new Date(item.start_date).setMinutes(startDate.getMinutes() - 10))

		if (now >= startDate && now >= tenMinutesBeforeStartDate) {
			setCannotCancelDialogVisible(true)
		} else if (now < startDate && now > tenMinutesBeforeStartDate) {
			setCancelMessageDialogVisible(true)
		} else {
			await handleCancel(item)
		}
	}

	async function handleCancel(item) {
		setLoading(true)
		await cancelBooking(item.id, !!props.juniorId)
		props.loadSessions()
		props.reloadAvailable()
		setCancelMessageDialogVisible(false)
		setLoading(false)
	}

	async function handleSendMessageAndCancel(item) {
		await handleCancel(item)
		await handleSendMessage(item)
	}

	async function handleCancelMessage(item) {
		setCannotCancelDialogVisible(false)
		await handleSendMessage(item)
	}

	function handleCategoryPress(categoryId) {
		setClickedCategoryId(categoryId)
		setSessionCategoryDialogVisible(true)
	}

	async function handlePressVolunteer(volunteerId, volunteerName) {
		if (!volunteerId) return
		const volunteerProfile = await getVolunteerProfile(volunteerId)
		setVolunteerProfile({ description: volunteerProfile?.description, volunteerName })
		setVolunteerProfileVisible(true)
	}

	return (
		<View style={styles.container}>
			{props.sessions.length === 0 ? (
				<View>
					<CustomText font="largeBold" style={styles.titleBold} accessibilityRole="header" aria-level={2}>
						{txt('videoChat.bookedSessions')}
					</CustomText>
					<CustomText font="small" style={styles.noSession}>{txt('videoChat.noBookedSessions')}</CustomText>
				</View>
			) : (
				<CustomText font="largeBold" style={styles.titleBold}>{txt('videoChat.bookedSessions')}</CustomText>
			)}
			{props.sessions.map((item, key) => {
				const date = formatUnixDate(item.start_date)
				return (
					<View key={key} style={styles.sessionContainer}>
						<View style={styles.textContainer}>
							<CustomText font="small">{date}</CustomText>
							<CustomText
								font="small"
								text={
									txt('videoChat.duration') +
									': ' +
									(new Date(item.end_date).getTime() - new Date(item.start_date).getTime()) / 1000 / 60 +
									' min.'
								}
							/>
							<CustomPressable onPress={() => handlePressVolunteer(item.volunteer_id, item.username)}>
								<CustomText
									font="small"
									text={txt('learnDanish.volunteer.title') + ': ' + item.username}
									numberOfLines={1}
									style={{flex: 1, flexWrap: 'wrap', textDecorationLine: 'underline'}}
								/>
							</CustomPressable>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
								{item.categories?.map((id, key) => (
									<CustomPressable
										onPress={() => handleCategoryPress(id)}
										accessibilityRole="button"
										key={key}
									>
										<TextLabel
										text={txt(`learnDanish.categories.${id}`)}
										style={{ marginRight: 6, marginTop: 6 }}
										showInfoIcon
										/>
									</CustomPressable>
								))}
							</View>
						</View>
						<View style={styles.buttonsContainer}>
							<View style={styles.buttonStartContainer}>
								<ButtonPrimary
									title={txt('videoChat.start')}
									onPress={() => handleStartPress(item)}
									titleStyle={{ ...TypographyStyles.textSmallBold }}
									style={styles.button}
									icon="video"
									iconSize={16}
									stroke={'fal'}
								/>
							</View>
							{props.startConversation && (
								<View style={styles.buttonConversationContainer}>
									<ButtonSecondary
										title={txt('conversations.messageSend')}
										onPress={() => handleSendMessage(item)}
										titleStyle={{ ...TypographyStyles.textSmallBold }}
										style={[styles.button, { borderWidth: 1 }]}
										icon="paper-plane"
										iconSize={16}
										stroke={'fal'}
									/>
								</View>
							)}
							<View style={styles.buttonCancelContainer}>
								<ButtonSecondary
									title={txt('videoChat.cancel')}
									titleStyle={{ ...TypographyStyles.textSmallBold, color: Colors.redCrossRed }}
									style={[styles.button, { borderWidth: 1, borderColor: Colors.redCrossRed }]}
									onPress={() => handleCancelBooking(item)}
									icon="times"
									iconSize={16}
									color={Colors.redCrossRed}
									stroke={'fal'}
									loading={loading}
								/>
							</View>
						</View>
						<CancelWithMessageDialog
							visible={cancelMessageDialogVisible}
							onDismiss={async () => await handleCancel(item)}
							sendMessage={() => handleSendMessageAndCancel(item)}
						/>
						<CannotCancelDialog
							visible={cannotCancelDialogVisible}
							onDismiss={() => setCannotCancelDialogVisible(false)}
							sendMessage={() => handleCancelMessage(item)}
						/>
					</View>
				)
			})}
			{/* <SproglandSurveyDialog
				visible={dialogVisible}
				onDismiss={() => setDialogVisible(false)}
			/> */}

			<SproglandJuniorConsentDialog
				visible={showConsentDialog}
				setVisible={(showConsentDialog) => setShowConsentDialog(showConsentDialog)}
				onCancel={() => setShowConsentDialog(false)}
				acceptOnly
			/>
			<SessionCategoryDialog
				visible={sessionCategoryDialogVisible}
				onDismiss={() => setSessionCategoryDialogVisible(false)}
				categoryId={clickedCategoryId}
			/>
			<VolunteerProfileDialog
				visible={volunteerProfileVisible}
				onDismiss={() => setVolunteerProfileVisible(false)}
				username={volunteerProfile?.volunteerName}
				description={volunteerProfile?.description}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	buttonCancelContainer: {
		flex: 1,
		justifyContent: 'center',
	},
	buttonConversationContainer: {
		flex: 1,
		justifyContent: 'center',
		paddingBottom: 10,
		paddingRight: 2,
	},
	buttonStartContainer: {
		flex: 1,
		justifyContent: 'center',
		paddingBottom: 10,
		paddingRight: 2,
	},
	buttonsContainer: {
		flexDirection: 'column',
		justifyContent: 'space-evenly',
	},
	button: {
		width: 150,
		paddingHorizontal: 0,
	},
	container: {
		margin: 10,
	},
	sessionContainer: {
		backgroundColor: Colors.white,
		borderRadius: 2,
		flexDirection: 'row',
		marginTop: 10,
		padding: 10,
		...ShadowStyles.liftedShadow,
	},
	noSession: {
		color: Colors.inactive,
		marginTop: 10,
		textAlign: 'center',
		backgroundColor: Colors.white,
		padding: 12,
		borderRadius: 2,
		...ShadowStyles.liftedShadow,
	},
	textContainer: {
		flex: 2,
		justifyContent: 'center',
		paddingRight: 12
	},
	titleBold: {
		textAlign: 'center',
	},
})

import React from 'react'
import { View, StyleSheet } from 'react-native'

import { ButtonPrimary, ButtonSecondary } from '../../../buttons/StyledButtons'
import CustomDialog, { ContentContainer } from '../../../dialogs/CustomDialog'
import CustomPressable from "../../../pressables/CustomPressable"
import { CustomText } from '../../../text/StyledText'
import NormalIcon from '../../../icons/NormalIcon'
import Colors from '../../../../constants/Colors'
import { txt } from '../../../../locales/i18n'

export default function PreCreateDialog({ visible, onDismiss, createSession }) {
	return (
		<CustomDialog visible={visible} onDismiss={onDismiss}>
			<CustomPressable onPress={onDismiss} style={styles.iconContainer}>
				<NormalIcon name="times" size={20} color={Colors.text} />
			</CustomPressable>

			<ContentContainer>
				<CustomText font="largeBold" style={{ marginRight: 24, marginBottom: 8 }}>
					{txt('learnDanish.volunteer.preCreate.title')}
				</CustomText>
				<CustomText>
					{txt('learnDanish.volunteer.preCreate.description')}
				</CustomText>
			</ContentContainer>
			<View style={styles.buttonContainer}>
				<ButtonSecondary
					title={txt('learnDanish.volunteer.preCreate.cancel')}
					onPress={onDismiss}
				/>
				<ButtonPrimary
					title={txt('learnDanish.volunteer.create.title')}
					onPress={createSession}
				/>
			</View>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	iconContainer: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	},
	buttonContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 8,
		marginBottom: 24,
	}
})

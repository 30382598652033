import React from 'react'
import { StyleSheet } from "react-native"

import CustomDialog, { ContentContainer } from "../../../dialogs/CustomDialog"
import CustomPressable from "../../../pressables/CustomPressable"
import { CustomText } from "../../../text/StyledText"
import NormalIcon from "../../../icons/NormalIcon"
import Colors from "../../../../constants/Colors"
import { txt } from '../../../../locales/i18n'

export function VolunteerProfileDialog({ visible, onDismiss, username, description }) {
	return (
		<CustomDialog visible={visible} onDismiss={onDismiss} style={styles.dialog}>

			<CustomPressable onPress={onDismiss} style={styles.iconContainer}>
				<NormalIcon name="times" size={20} color={Colors.text} />
			</CustomPressable>

			<ContentContainer>

				<CustomText font="bold" style={{ marginBottom: 12, paddingRight: 30 }}>{username}</CustomText>
				<CustomText style={{ marginBottom: 12, lineHeight: 20 }}>
					{description ? description : txt('learnDanish.volunteer.profile.dialog.noVolunteerProfile')}
				</CustomText>

			</ContentContainer>

		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	dialog: {
		borderRadius: 6,
		maxWidth: 500,
		minWidth: 300,
	},
	iconContainer: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	}
})

import React, { useEffect, useState } from 'react'
import { View, Image, StyleSheet, Linking, Platform } from 'react-native'

import { getUniverseConfig } from '../../universe/universeController'

import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import MainContainer from '../../components/MainContainer/MainContainer'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import NormalIcon from '../../components/icons/NormalIcon'
import CustomPressable from '../../components/pressables/CustomPressable'

export default function KrifaOffersScreen() {
	const [universeObject, setUniverseObject] = useState({})

	useEffect(() => {
		setUniverseObject(getUniverseConfig('krifa'))
	}, [])

	function handleOfferClick(url, offer) {
		if (Platform.OS === 'web') window.plausible('KrifaOfferClick', { props: { origin: offer } })
		if (Platform.OS !== 'web') {
			fetch('https://plausible.io/api/event', {
				method: 'POST',
				body: JSON.stringify({
					name: 'KrifaOfferClick',
					url: url,
					props: { origin: offer },
					domain: 'app.boblberg.dk,boblberg.samlet',
				}),
			})
		}
		Linking.openURL(url)
	}

	return (
		<MainContainer backgroundColor={universeObject.backgroundColor}>
			<View style={styles.container}>

				<CustomPressable
					style={styles.card}
					onPress={() => handleOfferClick('https://www.krifa.dk/medlemstilbud/tjoose', 'Tjoose')}
				>
					<Image
						accessibilityIgnoresInvertColors
						style={styles.backgroundImage}
						source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/krifa-leaf-15.png' }}
					/>
					<View style={styles.textContainer}>
						<CustomText font="largeBold" style={styles.cardTitle} lineHeight={100}>{txt('universe.krifa.offers.offer1')}</CustomText>
						<CustomText font={"small"}>{txt('universe.krifa.offers.offer1Text')}</CustomText>
					</View>
					<View>
						<NormalIcon name="chevron-right" size={14} color={universeObject.tabBarColor} style={styles.icon} />
					</View>
				</CustomPressable>

				<CustomPressable
					style={styles.card}
					onPress={() => handleOfferClick('https://www.krifa.dk/medlemstilbud/familieraadgivning', 'Familierådgivning')}
				>
					<Image
						accessibilityIgnoresInvertColors
						style={styles.backgroundImage}
						source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/krifa-leaf-15.png' }}
					/>
					<View style={styles.textContainer}>
						<CustomText font="largeBold" style={styles.cardTitle}>{txt('universe.krifa.offers.offer2')}</CustomText>
						<CustomText font={"small"}>{txt('universe.krifa.offers.offer2Text')}</CustomText>
					</View>
					<View>
						<NormalIcon name="chevron-right" size={14} color={universeObject.tabBarColor} style={styles.icon} />
					</View>
				</CustomPressable>

				<CustomPressable
					style={styles.card}
					onPress={() => handleOfferClick('https://krifa.dk/samtaler/stress-og-trivselssamtale', 'Stress- og trivselssamtale')}
				>
					<Image
						accessibilityIgnoresInvertColors
						style={styles.backgroundImage}
						source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/krifa-leaf-15.png' }}
					/>
					<View style={styles.textContainer}>
						<CustomText font="largeBold" style={styles.cardTitle}>{txt('universe.krifa.offers.offer3')}</CustomText>
						<CustomText font={"small"}>{txt('universe.krifa.offers.offer3Text')}</CustomText>
					</View>
					<View style={{alignItems: 'flex-end'}}>
						<NormalIcon name="chevron-right" size={14} color={universeObject.tabBarColor} style={styles.icon} />
					</View>
				</CustomPressable>

				<CustomPressable
					style={styles.card}
					onPress={() => handleOfferClick('https://krifa.dk/samtaler/jobskifte-og-karrieresamtale', 'Karrieresamtale')}
				>
					<Image
						accessibilityIgnoresInvertColors
						style={styles.backgroundImage}
						source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/krifa-leaf-15.png' }}
					/>
					<View style={styles.textContainer}>
						<CustomText font="largeBold" style={styles.cardTitle}>{txt('universe.krifa.offers.offer4')}</CustomText>
						<CustomText font={"small"}>{txt('universe.krifa.offers.offer4Text')}</CustomText>
					</View>
					<View style={{alignItems: 'flex-end'}}>
						<NormalIcon name="chevron-right" size={14} color={universeObject.tabBarColor} style={styles.icon} />
					</View>
				</CustomPressable>

			</View>
		</MainContainer>
	)
}

const styles = StyleSheet.create({
	backgroundImage: {
		position: 'absolute',
		height: 60,
		width: 100,
		top: 0,
		right: 0,
	},
	container: {
		alignSelf: 'center',
		marginHorizontal: 12,
		marginVertical: 48,
		maxWidth: Layout.isSmallDevice ? '100%' : Layout.maxFeedWidth,
	},
	card: {
		flexDirection: 'row',
		alignItems: 'center',
		maxWidth: Layout.isSmallDevice ? '100%' : 400,
		width: '100%',

		backgroundColor: Colors.white,
		borderRadius: 8,
		paddingHorizontal: 24,
		paddingVertical: 24,
		marginBottom: 24,

		/* SHADOW */
		shadowColor: Colors.grey,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 20,
		elevation: 15,
	},
	cardTitle: {
		marginBottom: 8,
		color: '#651560',
	},
	textContainer: {
		flex: 1,
	},
	icon: {
		width: 16,
		height: 16,
		marginLeft: 8,
	},
})

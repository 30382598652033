import { apiDelete, apiGet, apiPost, apiPut } from './ApiRequest'
import { updateUser } from "./User"

const stopLoadingFunc = (success) => {
  // if (__DEV__) console.log('No stopLoadingFunc passed, success: ' + success)
}

// SLBook
export async function bookSession(session_id, juniorId) {
  const body = { video_session_id: session_id }
  if (juniorId) body.juniorId = juniorId
  try {
    const response = await apiPost(body, 'sl/book')
    const responseJson = await response.json()
    const statusCode = response.status
    return { statusCode, ...responseJson }
  } catch (error) {
    if (__DEV__) console.log(error)
    return { err_code: 'SERVER_ERROR' }
  }
}

// SLCancelBooking
export async function cancelBooking(id, juniorSession, stopLoading = stopLoadingFunc) {
  const jsonData = { video_session_id: id }
  let url = 'sl/cancelBooking'
  if (juniorSession) url += `?junior=${true}`

  const response = await apiPost(jsonData, url)
  if (!response) stopLoading(false)
  else stopLoading(true)
}

// SLCreate
export async function createBooking({startDate, endDate, emailToInvite, junior = false, juniorId, categories}) {
  let body = {
    start_date: startDate,
    end_date: endDate,
    junior: junior,
    juniorId,
    categories,
  }
  if (emailToInvite) {
    body.email_to_invite = emailToInvite
  }

  // Wait for response then parse to json
  return await apiPost(body, 'sl/create')
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.data) {
        return
      }
      return responseJson.data[0]
    })
    .catch((error) => {
      if (__DEV__) console.error("Couldn't create booking.")
      if (__DEV__) console.error(error)
    })
}

export async function preCreateBooking(emailToInvite) {
	const res = await apiGet(`sl/preCreateCheck?email=${emailToInvite}`)
	return res
}

// SLDelete
export async function deleteBooking(id, juniorSession) {
  const jsonData = { video_session_id: id, junior: !!juniorSession }
  const response = await apiPost(jsonData, 'sl/delete')
  if (!response) return false
  else return true
}

// SLGetAvailable
export async function getAvailable(start_date, end_date, juniorSessions) {
	if (!start_date || !end_date) {
		if (__DEV__) console.error('Start- or end-date missing.')
		return
	}

	const body = { start_date, end_date }
	let url = 'sl/getAvailable'
	if (juniorSessions) url += `?junior=${true}`

	try {
		// TODO: rewrite to use apiGet
		const response = await apiPost(body, url)
		if (!response) return []
		const responseJson = await response.json()
		
		extractCategories(responseJson.data)
		return responseJson.data
	} catch (error) {
		if (__DEV__) console.error('Failed to fetch sessions.')
		console.error(error)
	}
}

// SLGetAvailableDates
export async function getAvailableDates(juniorSession) {
  let url = 'sl/getAvailableDates'
  if (juniorSession) url += `?junior=${true}`
  const res = await apiGet(url)
  if (res?.data) {
    return res.data.map((val, index) => ({ key: index, ...val }))
  }
  return []
}

// SLGetBooked
export async function getBookedSessions(juniorId) {
  let url = 'sl/getBooked'
  if (juniorId) url += `?juniorId=${juniorId}`
  const res = await apiGet(url)
  if (res?.data) {
    extractCategories(res.data)
    return res.data.map((val, index) => ({ key: index, ...val }))
  }
  return []
}

export async function getOwnBooked() {
  const res = await apiGet('sl/getOwnBooked')
  if (!res?.data) return []
  res.data = res.data.map((val, index) => ({ key: index, ...val }))
  extractCategories(res.data)
  return res.data
}

export async function getOwnNotBooked() {
  const res = await apiGet('sl/getOwnNotBooked')
  if (!res?.data) return []
  res.data = res.data.map((val, index) => ({ key: index, ...val }))
  extractCategories(res.data)
  return res.data
}

// SLGetAllSessions
export async function getAllSessions() {
  const res = await apiGet('sl/getAllSessions')
  if (!res?.data) return []
  extractCategories(res.data)
  return res.data
}

// SLVolunteerHasAccess
export async function volunteerHasAccess(
  organization_name,
  password,
  stopLoading = stopLoadingFunc
) {
  if (organization_name == null || password == null) {
    if (__DEV__)
      console.log(
        'No organization_name or password was passed to check if volunteer has access function, returning.'
      )
    stopLoading(false)
    return
  }
  const data = {
    organization_name,
    password,
  }

  return apiPost(data, `sl/volunteerHasAccess`)
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.data) {
        stopLoading(false)
        return
      }

      stopLoading(true)
      return responseJson.data[0]
    })
    .catch((error) => {
      if (__DEV__) console.error(error)
      stopLoading(false)
    })
}

export async function addToJuniorWaitList() {
  const res = await apiPost({}, 'sl/junior/waitList')
  const resJson = await res.json()
  return Boolean(resJson?.status)
}

export async function getJuniorWaitListStatus() {
  const res = await apiGet('sl/junior/waitList')
  return Boolean(res?.status)
}

export async function getJuniorProfileList(email) {
  const res = await apiGet(`sl/juniors?email=${email}`)
  return res.data
}

function extractCategories(sessions) {
  for (let session of sessions) {
    if (session.categories) {
      session.categories = JSON
        .parse(session.categories)
        .map(category => category.categoryId)
    }
  }
}

export async function getPreviousBookedSessions() {
  const res = await apiGet('sl/previous')
  if (!res?.data) return []
  res.data = res.data.map((val, index) => ({ key: index, ...val }))
  extractCategories(res.data)
  return res.data
}

export async function getPreviousBookers() {
  const res = await apiGet('sl/previousBookers')
  if (!res?.data) return []
  return res.data
}

export async function getPreviousJuniorBookers() {
  const res = await apiGet('sl/previousJuniorBookers')
  return res?.data ?? []
}

export async function getSessionCount() {
  const res = await apiGet('sl/count')
  if (res?.data === undefined) return
  return res.data
}

export async function addToWaitList() {
  const res = await apiPost({}, 'sl/waitList')
  const resJson = await res.json()
  return Boolean(resJson?.status)
}

export async function getWaitListStatus() {
  const res = await apiGet('sl/waitList')
  return Boolean(res?.status)
}

export async function getJuniorConsent() {
  const response = await apiGet('sl/junior-consent')
  return response?.consentGiven
}

export async function updateJuniorConsent(sproglandJuniorConsent) {
  const response = await apiPut({ sproglandJuniorConsent }, 'sl/junior-consent')
  if (response.ok === false) throw new Error("Could'n get junior consent.")
}

export async function getCountriesList() {
  const response = await apiGet('sl/countries')
  return response?.data
}

export async function getUserCountry() {
  const response = await apiGet('sl/user-country')
  return response?.data
}

export async function setUserCountry(countryCode) {
  const response = await apiPost({countryCode}, 'sl/user-country')
  return response?.ok
}

export async function getVolunteerProfile(userId) {
	const res = await apiGet(`sl/volunteerProfile/${userId}`)
	if (!res?.data) return null
	return res.data
}

export async function getSproglandProfile() {
	const res = await apiGet('sl/profile')
	if (!res?.data) return null
	return res.data
}

export async function updateSproglandProfile({ description = null, askAgain = null, data = {} }) {
	// save profile
	const body = {}
	if (description !== null) body.description = description
	if (askAgain !== null) body.askAgain = askAgain

	let res = await apiPost(body, 'sl/profile')
	if (!res.ok) return false

	// save user info
	if (Object.keys(data).length === 0) return
	return await updateUser(data)
}

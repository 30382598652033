import React from "react"
import { StyleSheet, View } from "react-native"
import { useNavigation } from "@react-navigation/core"

import { updateSproglandProfile } from "../../../../services/api/LearnDanish"

import CustomDialog, { ContentContainer } from "../../../dialogs/CustomDialog"
import { ButtonPrimary, ButtonSecondary } from "../../../buttons/StyledButtons"
import CustomPressable from "../../../pressables/CustomPressable"
import NormalIcon from "../../../icons/NormalIcon"
import Colors from "../../../../constants/Colors"
import { CustomText } from "../../../text/StyledText"
import { txt } from "../../../../locales/i18n"

export function ProfileCompletionDialog({ visible, onDismiss }) {
	const navigation = useNavigation()

	async function handleDontAskAgain() {
		await updateSproglandProfile({ askAgain: false })
		onDismiss()
	}

	return (
		<CustomDialog visible={visible} onDismiss={onDismiss} style={styles.dialog}>

			<CustomPressable onPress={onDismiss} style={styles.iconContainer}>
				<NormalIcon name="times" size={20} color={Colors.text} />
			</CustomPressable>

			<ContentContainer>

				<CustomText font="bold" style={{ marginBottom: 12 }}>{txt('learnDanish.volunteer.profile.dialog.title')}</CustomText>
				<CustomText font="small" style={styles.body}>{txt('learnDanish.volunteer.profile.dialog.body')}</CustomText>

				<View style={styles.buttonContainer}>
					<ButtonSecondary
						title={txt('learnDanish.volunteer.profile.dialog.noThanks')}
						onPress={handleDontAskAgain}
						style={styles.button}
						titleStyle={styles.buttonTitle}
					/>
					<ButtonPrimary
						title={txt('learnDanish.volunteer.profile.dialog.goToProfile')}
						onPress={() => navigation.setParams({ chip: txt('learnDanish.volunteer.chipProfile') })}
						style={[styles.button, styles.ButtonPrimary]}
						titleStyle={[styles.buttonTitle, styles.ButtonPrimaryTitle]}
					/>
				</View>

			</ContentContainer>

		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	body: {
		lineHeight: 20,
		marginBottom: 12,
	},
	buttonContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: 24,
	},
	dialog: {
		borderRadius: 6,
		maxWidth: 500,
	},
	iconContainer: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	}
})